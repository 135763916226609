import React, { ReactNode } from 'react'
import styles from './scss/info-panel.module.scss'
import { Heading4 } from '@greensync/component-library'

type PanelProps = {
  header: string
  children: ReactNode
}

const InfoPanel: React.FC<PanelProps> = ({ header, children }) => {
  return (
    <div className={styles.infoPanel}>
      <Heading4 className={styles.header}>{header}</Heading4>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export default InfoPanel
