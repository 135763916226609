import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { IconLoader } from '@greensync/component-library'
import InfoPanel from '../../components/InfoPanel'
import PanelWithContentLayout from '../../components/PageLayout/PanelWithContentLayout'
import { OffersList } from './OffersList'
import Notification from '../../components/Notification'
import ErrorNotification from '../../components/ErrorNotification'

type OffersPageProps = {}

export const getOffersQuery = gql`
  query Offers {
    offers {
      id
      offerType
      retailer
      deviceType
      deviceManufacturers
      state
      imageUrl
      externalUrl
    }
  }
`

export const OffersPage: React.FC<OffersPageProps> = () => {
  const { loading, error, data: offersResponse } = useQuery(getOffersQuery)
  const offers = (offersResponse && offersResponse.offers) || []

  const renderOffersList = () => {
    if (loading) {
      return <IconLoader />
    } else if (error) {
      return <ErrorNotification />
    } else if (offers.length === 0) {
      return (
        <Notification>
          <div>We're afraid you're not eligible for any offers at this stage - check back here later when we have more!</div>
        </Notification>
      )
    } else {
      return <OffersList offers={offers} />
    }
  }

  return (
    <React.Fragment>
      <Helmet title="Offers" />
      <PanelWithContentLayout>
        <InfoPanel header="Offers">
          <div>
            <p>
              Thanks for providing your details. The offers shown in this screen are available to you. Click on ‘view offer’ to get started
              with your chosen provider.
            </p>
            <p>Your provider will supply more information about the registration incentive.</p>
            <p>
              If you have questions, you can <Link to="/contact_support">Contact Us</Link>
            </p>
          </div>
        </InfoPanel>

        {renderOffersList()}
      </PanelWithContentLayout>
    </React.Fragment>
  )
}

export default OffersPage
