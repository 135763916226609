import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import buttonStyles from '../../styles/button.module.scss'
import styles from './scss/confirm-action-modal.module.scss'
import cx from 'classnames'

type ConfirmActionModalProps = {
  buttonLabel: string
  buttonClassNames: string
  targetUrl: string
}

const ConfirmActionModal: React.FC<ConfirmActionModalProps> = ({ buttonLabel, buttonClassNames, targetUrl }) => {
  const [active, setActive] = useState(false)

  const renderTargetLink = () => {
    return (
      <Link to={targetUrl} className={cx('button', buttonStyles.roundedButton, buttonStyles.noLeftMargin)} data-test-id="target-link">
        {buttonLabel}
      </Link>
    )
  }

  const renderModalTrigger = () => {
    return (
      <button className={buttonClassNames} onClick={() => setActive(true)} data-test-id="modal-trigger-btn">
        {buttonLabel}
      </button>
    )
  }

  const renderModal = () => {
    return (
      <div className={cx('modal', { 'is-active': active }, styles.modal)}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Confirm action</p>
            <button className="delete" aria-label="close" data-test-id="close-btn" onClick={() => setActive(false)}></button>
          </header>
          <section className="modal-card-body">
            <p>You have unsaved changes that will be lost if you proceed to the "{buttonLabel}" page.</p>
            <p>Click "Cancel" if you would like to continue editing.</p>
          </section>
          <footer className="modal-card-foot">
            <button className={cx('button', buttonStyles.roundedButtonPlain)} onClick={() => setActive(false)} data-test-id="cancel-btn">
              Cancel
            </button>
            {renderTargetLink()}
          </footer>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      {renderModal()}
      {renderModalTrigger()}
    </React.Fragment>
  )
}

export default ConfirmActionModal
