import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/react'
import './index.scss'
import globalConfig from './global_config'
import { gaConfiguration } from './configuration'
import * as serviceWorker from './serviceWorker'
import App from './App'

const { sentryDsn: dsn, environment, gaTrackingCode } = globalConfig
Sentry.init({ dsn, environment: `${environment}-frontend` })
ReactGA.initialize(gaTrackingCode || 'dummy', gaConfiguration) // empty string == false

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
