import React, { useState, useContext, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useMutation } from '@apollo/react-hooks'
import AppContext from '../../store/context/AppContext'
import InfoPanel from '../../components/InfoPanel'
import CollapsibleFormContainer, { CollapsibleFormContainerComponent } from '../../components/FormContainer/CollapsibleFormContainer'
import PanelWithContentLayout from '../../components/PageLayout/PanelWithContentLayout'
import NewLocationForm, { locationFormFields } from './NewLocationForm'
import NewDeviceForm, { deviceFormFields } from './NewDeviceForm'
import { REGISTER_LOCATION_MUTATION } from '../../mutations/RegisterLocationMutation'
import { REGISTER_DEVICE_MUTATION } from '../../mutations/RegisterDeviceMutation'
import { handleGraphQLErrors } from '../../helpers/GraphQLErrorHandlers'
import { Device } from '../../types/Device'
import { Location } from '../../types/Location'
import buttonStyles from '../../styles/button.module.scss'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import inputSizeClass from '../../helpers/InputSizeHelper'
import ConfirmActionModal from '../../components/ConfirmActionModal'
import ErrorNotification from '../../components/ErrorNotification'

type DeviceRegistrationPageProps = {}

const DeviceRegistrationPage: React.FC<DeviceRegistrationPageProps> = () => {
  const { store, dispatch } = useContext(AppContext)
  const { lastRegisteredLocation, devices } = store
  const currentLocationDevices = devices.filter((d) => lastRegisteredLocation && d.location && d.location.id === lastRegisteredLocation.id)

  const [newDevice, setNewDevice] = useState(currentLocationDevices.length === 0)
  const [locationFormErrors, setLocationFormErrors] = useState({})
  const [deviceFormErrors, setDeviceFormErrors] = useState({})
  const [genericError, setGenericError] = useState(false)

  let locationFormContainerRef = useRef<CollapsibleFormContainerComponent>()

  const toggleLocationFormContainer = () => {
    const element = locationFormContainerRef.current
    if (element) {
      element.toggleIsCollapsed()
    }
  }

  const [registerLocationMutation] = useMutation(REGISTER_LOCATION_MUTATION, {
    errorPolicy: 'all',
    onCompleted: (data) => {
      dispatch({
        type: 'AddLocation',
        payload: {
          location: data.registerLocation.location,
        },
      })

      setGenericError(false)
      setLocationFormErrors([])
      toggleLocationFormContainer()
    },
    onError: (errorInfo) => {
      const [errors, formErrors] = handleGraphQLErrors(errorInfo, locationFormFields)
      if (errors.length > 0) {
        setGenericError(true)
      }
      if (formErrors) {
        setLocationFormErrors(formErrors)
      }
    },
  })

  const [registerDeviceMutation] = useMutation(REGISTER_DEVICE_MUTATION, {
    errorPolicy: 'all',
    onCompleted: (data) => {
      dispatch({
        type: 'AddDevice',
        payload: {
          device: data.registerDevice.device,
        },
      })

      setGenericError(false)
      setDeviceFormErrors([])
      setNewDevice(false)
    },
    onError: (errorInfo) => {
      const [errors, formErrors] = handleGraphQLErrors(errorInfo, deviceFormFields)
      if (errors.length > 0) {
        setGenericError(true)
      }
      if (formErrors) {
        setDeviceFormErrors(formErrors)
      }
    },
  })

  const onNewLocation = () => {
    dispatch({ type: 'ResetLocation' })
    setNewDevice(true)
    toggleLocationFormContainer()
  }

  const locationCreated = lastRegisteredLocation !== undefined

  const renderLinkToOffersPage = () => {
    return (
      <Link to="/offers" className={cx('button', inputSizeClass, buttonStyles.roundedButton, buttonStyles.fullWidth)}>
        View offers
      </Link>
    )
  }

  const renderConfirmActionModal = () => {
    return (
      <ConfirmActionModal
        buttonLabel="View offers"
        buttonClassNames={cx('button', inputSizeClass, buttonStyles.roundedButton, buttonStyles.fullWidth)}
        targetUrl="/offers"
      />
    )
  }

  return (
    <React.Fragment>
      <Helmet title="Device Registration" />
      <PanelWithContentLayout>
        <InfoPanel header="Device Registration">
          <p>
            In order to register your devices, you will need to give us information about the location of your devices. If you're not sure
            where to find some location details, please hover the info icons located near the input fields.
          </p>
          <p>After you've saved the location, you can add multiple devices for your location.</p>
          <p>If you have questions or comments, please navigate to our contact page and type in your message. Thank you!</p>
        </InfoPanel>

        <div>
          {genericError && <ErrorNotification />}

          <CollapsibleFormContainer
            step={1}
            title="Device location"
            completed={locationCreated}
            initiallyCollapsed={locationCreated}
            disabled={false}
            ref={locationFormContainerRef}
          >
            <NewLocationForm
              value={lastRegisteredLocation}
              readOnly={locationCreated}
              fieldErrors={locationFormErrors}
              onFormComplete={(loc: Location) => registerLocationMutation({ variables: loc })}
            />
          </CollapsibleFormContainer>

          {currentLocationDevices.map((device: any) => {
            return (
              <CollapsibleFormContainer
                step={2}
                title="Device details"
                completed={true}
                key={device.id}
                initiallyCollapsed={true}
                disabled={false}
              >
                <NewDeviceForm value={device} readOnly={true} />
              </CollapsibleFormContainer>
            )
          })}

          {newDevice && (
            <CollapsibleFormContainer
              step={currentLocationDevices.length + 2}
              title="Device details"
              disabled={!locationCreated}
              initiallyCollapsed={false}
            >
              <NewDeviceForm
                readOnly={false}
                fieldErrors={deviceFormErrors}
                onFormComplete={(device: Device) =>
                  registerDeviceMutation({
                    variables: Object.assign(device, { locationId: lastRegisteredLocation!.id }),
                  })
                }
              />
            </CollapsibleFormContainer>
          )}

          {locationCreated && currentLocationDevices.length > 0 && (
            <div className="columns is-desktop" data-test-class="action-buttons">
              <div className="column">
                <button
                  name="addLocation"
                  className={cx('button', inputSizeClass, buttonStyles.roundedButtonPlain, buttonStyles.fullWidth)}
                  onClick={onNewLocation}
                >
                  Add another location
                </button>
              </div>
              <div className="column">
                <button
                  name="addDevice"
                  className={cx('button', inputSizeClass, buttonStyles.roundedButtonPlain, buttonStyles.fullWidth)}
                  onClick={() => setNewDevice(true)}
                >
                  Add another device
                </button>
              </div>
              <div className="column">{newDevice ? renderConfirmActionModal() : renderLinkToOffersPage()}</div>
            </div>
          )}
        </div>
      </PanelWithContentLayout>
    </React.Fragment>
  )
}

export default DeviceRegistrationPage
