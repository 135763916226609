import React, { useState, useEffect, ReactNode, useContext } from 'react'
import AppContext from '../../store/context/AppContext'
import { useApolloClient } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { IconLoader } from '@greensync/component-library'
import styles from './scss/user-information.module.scss'
import ErrorNotification from '../ErrorNotification'

type UserInformationProps = {
  children: ReactNode
}

export const userInformationQuery = gql`
  query Viewer {
    viewer {
      userEmail
      privateCitizen {
        id
        firstName
        lastName
        email
        phone
        locations {
          id
          nmi
          postCode
          state
          retailer
          devices {
            id
            deviceType
            manufacturer
            model
            serialNumber
            capacityKva
          }
        }
      }
    }
  }
`
const UserInformation: React.FC<UserInformationProps> = ({ children }) => {
  const apolloClient = useApolloClient()

  const { dispatch } = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    apolloClient
      .query({ query: userInformationQuery })
      .then((result) => {
        if (result.data.viewer) {
          dispatch({
            type: 'SetStateFromViewerQuery',
            payload: { viewer: result.data.viewer },
          })
        }
      })
      .catch((error) => setError(true))
      .finally(() => setLoading(false))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <div className={styles.container}>
        <IconLoader />
      </div>
    )
  }

  if (error) {
    return <ErrorNotification />
  }

  return <div className={styles.container}>{children}</div>
}

export default UserInformation
