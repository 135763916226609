import React, { useReducer, ReactNode } from 'react'
import reducer from './reducer'
import AppContext from './context/AppContext'
import { initialState } from './initialState'

interface StoreProps {
  children: ReactNode
}

export function Store({ children }: StoreProps) {
  const [store, dispatch] = useReducer(reducer, initialState)

  return <AppContext.Provider value={{ store, dispatch }}>{children}</AppContext.Provider>
}
