import React, { useState } from 'react'
import Select from '../../components/Select'
import InfoMsg from '../../components/InfoMsg'
import { Device } from '../../types/Device'
import { deviceDefaults, manufacturersData } from '../../configuration'
import { FormErrorHelper } from '../../helpers/FormErrorHelper'
import { FieldErrors } from '../../helpers/GraphQLErrorHandlers'
import buttonStyles from '../../styles/button.module.scss'
import cx from 'classnames'
import inputSizeClass from '../../helpers/InputSizeHelper'

type NewDeviceFormProps = {
  value?: Device
  readOnly: boolean
  onFormComplete?: Function
  fieldErrors?: FieldErrors
}

export const deviceFormFields = ['deviceType', 'manufacturer', 'customManufacturer', 'model', 'serialNumber', 'capacityKva']

const NewDeviceForm: React.FC<NewDeviceFormProps> = ({ value, readOnly, onFormComplete, fieldErrors }) => {
  const currentManufacturer = (value && value.manufacturer) || deviceDefaults.manufacturer
  let manufacturerValue = currentManufacturer
  let customManufacturerValue = ''
  if (currentManufacturer.length > 0 && !manufacturersData.includes(currentManufacturer)) {
    manufacturerValue = 'Other'
    customManufacturerValue = currentManufacturer
  }

  const [deviceType, setDeviceType] = useState((value && value.deviceType) || deviceDefaults.deviceType)
  const [manufacturer, setManufacturer] = useState(manufacturerValue)
  const [customManufacturer, setCustomManufacturer] = useState(customManufacturerValue)
  const [model, setModel] = useState((value && value.model) || deviceDefaults.model)
  const [serialNumber, setSerialNumber] = useState((value && value.serialNumber) || deviceDefaults.serialNumber)
  const [capacityKva, setCapacityKva] = useState((value && value.capacityKva) || deviceDefaults.capacityKva)

  const onDeviceTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeviceType(event.target.value)
  }
  const onManufacturerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setManufacturer(event.target.value)
  }
  const onCustomManufacturerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomManufacturer(event.target.value.trim())
  }
  const onModelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModel(event.target.value.trim())
  }
  const onSerialNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSerialNumber(event.target.value.trim())
  }
  const onCapacityKvaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCapacityKva(parseFloat(event.target.value))
  }

  const manufacturerUnknown = manufacturer === 'Other'

  const errorHelper = new FormErrorHelper(fieldErrors)

  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    let device = {
      deviceType,
      manufacturer: manufacturer === 'Other' ? customManufacturer : manufacturer,
      model,
      serialNumber,
      capacityKva,
    }
    onFormComplete && onFormComplete(device)
  }

  const systemTypeOptions = [
    { label: 'EV Charger', value: 'battery_only' },
    { label: 'Battery Only', value: 'battery_only' },
    { label: 'Battery & Solar', value: 'battery_and_solar' },
    { label: 'Solar Only', value: 'solar_only' },
  ]

  const typeLabel = () => {
    switch (deviceType) {
      case 'battery_only':
        return 'Battery'
      case 'solar_only':
        return 'Solar'
      default:
        return 'System'
    }
  }

  return (
    <form onSubmit={onSubmitForm} className="device-form">
      <fieldset disabled={readOnly}>
        <div className="columns is-desktop">
          <div className="column is-four-fifths-desktop">
            <div className="columns is-desktop">
              <div className="column">
                <InfoMsg>
                  <ul>
                    <li>Battery Only system is an inverter connected to a battery</li>
                    <li>Battery & Solar system is an inverter connected to a solar panel and battery</li>
                    <li>Solar Only system is an inverter connected to a solar panel</li>
                  </ul>
                </InfoMsg>
              </div>
            </div>

            <div className="columns is-desktop">
              <div className="column is-half-desktop">
                <div className="field typeField">
                  <label className="label">System type *</label>
                  <div className="control">
                    <div className={cx('select', inputSizeClass)}>
                      <Select
                        structuredOptions={systemTypeOptions}
                        name="deviceType"
                        onChange={onDeviceTypeChange}
                        defaultValue={deviceType}
                      />
                    </div>
                  </div>
                  {errorHelper.renderError('deviceType')}
                </div>
              </div>
            </div>

            <div className="columns is-desktop">
              <div className="column is-half-desktop">
                <div className="field manufacturerField">
                  <label className="label">{typeLabel()} inverter manufacturer *</label>
                  <div className="control">
                    <div className={cx('select', inputSizeClass)}>
                      <Select
                        options={manufacturersData}
                        includeEmptyOption={true}
                        name="manufacturer"
                        onChange={onManufacturerChange}
                        defaultValue={manufacturer}
                      />
                    </div>
                  </div>
                  {errorHelper.renderError('manufacturer')}
                </div>
              </div>

              {manufacturerUnknown && (
                <div className="column is-half-desktop">
                  <div className="field customManufacturerField">
                    <label className="label">Please specify manufacturer *</label>
                    <div className="control">
                      <input
                        className={cx('input', inputSizeClass)}
                        type="text"
                        name="customManufacturer"
                        value={customManufacturer}
                        onChange={onCustomManufacturerChange}
                        onBlur={onCustomManufacturerChange}
                        required
                        minLength={2}
                        autoFocus
                      />
                    </div>
                    {errorHelper.renderError('customManufacturer')}
                  </div>
                </div>
              )}
            </div>

            <div className="columns is-desktop">
              <div className="column is-half-desktop">
                <div className="field modelField">
                  <label className="label">{typeLabel()} inverter model *</label>
                  <div className="control">
                    <input
                      className={cx('input', inputSizeClass)}
                      type="text"
                      name="model"
                      required
                      minLength={2}
                      value={model}
                      onChange={onModelChange}
                      onBlur={onModelChange}
                    />
                  </div>
                  {errorHelper.renderError('model')}
                </div>
              </div>

              <div className="column is-half-desktop">
                <div className="field serialNumberField">
                  <label className="label">{typeLabel()} inverter serial number *</label>
                  <div className="control">
                    <input
                      className={cx('input', inputSizeClass)}
                      type="text"
                      name="serialNumber"
                      required
                      minLength={2}
                      value={serialNumber}
                      onChange={onSerialNumberChange}
                      onBlur={onSerialNumberChange}
                    />
                  </div>
                  {errorHelper.renderError('serialNumber')}
                </div>
              </div>
            </div>

            <div className="columns is-desktop">
              <div className="column is-half-desktop">
                <div className="field capacityKvaField">
                  <label className="label">System capacity *</label>
                  <div className="field has-addons">
                    <div className="control">
                      <input
                        className={cx('input', inputSizeClass)}
                        type="number"
                        name="capacityKva"
                        required
                        min={0.1}
                        step={0.1}
                        value={capacityKva}
                        onChange={onCapacityKvaChange}
                      />
                    </div>
                    <div className="control">
                      <button className={cx('button', 'is-static', inputSizeClass)}>kVA</button>
                    </div>
                  </div>
                  {errorHelper.renderError('capacityKva')}
                </div>
              </div>
            </div>

            {!readOnly && (
              <div className="columns is-desktop">
                <div className="column">
                  <button type="submit" className={cx('button', inputSizeClass, buttonStyles.roundedButton)}>
                    Save
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </fieldset>
    </form>
  )
}

export default NewDeviceForm
