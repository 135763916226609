import React from 'react'
import { Helmet } from 'react-helmet-async'
import FullContentLayout from '../../components/PageLayout/FullContentLayout'
import FormContainer from '../../components/FormContainer/FormContainer'

class ContactPage extends React.Component {
  componentDidMount() {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if ((window as any).hbspt) {
        ;(window as any).hbspt.forms.create({
          portalId: '3966708',
          formId: '30ff4bd1-d409-4785-8e9f-581d8d405bb4',
          target: '#contactPage',
        })
      }
    })
  }

  render() {
    return (
      <React.Fragment>
        <Helmet title="Contact" />
        <FullContentLayout>
          <FormContainer step="?" header="Contact support">
            <div id="contactPage"></div>
          </FormContainer>
        </FullContentLayout>
      </React.Fragment>
    )
  }
}

export default ContactPage
