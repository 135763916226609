import gql from 'graphql-tag'

export const REGISTER_DEVICE_MUTATION = gql`
  mutation RegisterDevice(
    $locationId: String!
    $deviceType: String!
    $manufacturer: String!
    $model: String!
    $serialNumber: String!
    $capacityKva: Float!
  ) {
    registerDevice(
      input: {
        locationId: $locationId
        deviceType: $deviceType
        manufacturer: $manufacturer
        model: $model
        serialNumber: $serialNumber
        capacityKva: $capacityKva
      }
    ) {
      device {
        id
        deviceType
        manufacturer
        model
        serialNumber
        capacityKva
        location {
          id
        }
      }
    }
  }
`
