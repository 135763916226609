import React from 'react'
import styles from './scss/pc-registration-page.module.scss'
import { FormValues } from './types/FormValues'
import { FormErrorsByField } from './types/FormErrors'
import InfoMsg from '../../components/InfoMsg'
import cx from 'classnames'

type TermsAndConditionsFormProps = {
  formValues: FormValues
  formErrorsByField: FormErrorsByField
  onChange: any
}

const TermsAndConditionsForm: React.FC<TermsAndConditionsFormProps> = ({ formValues, formErrorsByField, onChange }) => {
  const termsAndConditionsError = formErrorsByField['termsAndConditions']

  return (
    <div className="columns is-desktop">
      <div className="column is-four-fifths-desktop">
        <InfoMsg>
          <p>In submitting this form, you:</p>

          <ol>
            <li>
              acknowledge that GreenSync Pty Ltd will collect:
              <ul>
                <li>the information included in this form (including any of your personal or sensitive information); and</li>
                <li>data streams associated with your energy devices or assets, at 1 – 300 second intervals</li>
              </ul>
              for the purposes of your registration and use of the deX platform, and enabling any related communications; and
            </li>
            <li>
              consent to GreenSync Pty Ltd collecting and handling this information (including your personal and sensitive information) in
              accordance with its&nbsp;
              <a href="https://dex.energy/privacy-policy/" target="_blank" rel="noopener">
                Privacy Policy
              </a>
              . Please see our&nbsp;
              <a href="https://dex.energy/privacy-policy/" target="_blank" rel="noopener">
                Privacy Policy
              </a>
              &nbsp;for further details about how we use and disclose your personal information (including how we might use it to
              communicate with you about our products and services) and how you can contact us to access or correct your personal
              information, or make a complaint.
            </li>
          </ol>
        </InfoMsg>

        <div className={cx('field', styles.termsAndConditionsField)}>
          <label className={cx('checkbox', styles.checkboxLabel)}>
            <input name="termsAndConditions" type="checkbox" required checked={formValues.termsAndConditions} onChange={onChange} />I agree
            to the terms and conditions
          </label>

          {termsAndConditionsError && <p className="help is-danger">{termsAndConditionsError}</p>}
        </div>
      </div>
    </div>
  )
}

export default TermsAndConditionsForm
