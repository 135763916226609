import React, { useState, useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import AppContext from '../../store/context/AppContext'
import InfoPanel from '../../components/InfoPanel'
import FormContainer from '../../components/FormContainer/FormContainer'
import PanelWithContentLayout from '../../components/PageLayout/PanelWithContentLayout'
import UserInfoForm from './UserInfoForm'
import TermsAndConditionsForm from './TermsAndConditionsForm'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import buttonStyles from '../../styles/button.module.scss'
import cx from 'classnames'
import inputSizeClass from '../../helpers/InputSizeHelper'
import ErrorNotification from '../../components/ErrorNotification'
import { handleGraphQLErrors } from '../../helpers/GraphQLErrorHandlers'

type PCRegistrationPageProps = {}

export const REGISTER_MUTATION = gql`
  mutation RegisterPrivateCitizen($firstName: String!, $lastName: String!, $phone: String, $termsAndConditions: Boolean!) {
    registerPrivateCitizen(input: { firstName: $firstName, lastName: $lastName, phone: $phone, termsAndConditions: $termsAndConditions }) {
      privateCitizen {
        id
        email
        firstName
        lastName
        phone
      }
    }
  }
`

export const formFields = ['firstName', 'lastName', 'phone', 'termsAndConditions']

const PCRegistrationPage: React.FC<PCRegistrationPageProps> = () => {
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    termsAndConditions: false,
  })
  const [formErrors, setFormErrors] = useState({})
  const [genericError, setGenericError] = useState(false)

  const { dispatch } = useContext(AppContext)

  const [registerPrivateCitizen] = useMutation(REGISTER_MUTATION, {
    errorPolicy: 'all',
    onCompleted: (data) => {
      setGenericError(false)
      dispatch({
        type: 'SetPrivateCitizen',
        payload: {
          privateCitizen: data.registerPrivateCitizen.privateCitizen,
        },
      })
    },
    onError: (errorInfo) => {
      const [errors, formErrors] = handleGraphQLErrors(errorInfo, formFields)
      if (errors.length > 0) {
        setGenericError(true)
      }
      if (formErrors) {
        setFormErrors(formErrors)
      }
    },
  })

  const handleFormFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.target.name
    let newValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    setFormValues({
      ...formValues,
      [fieldName]: newValue,
    })
  }

  const nullIfEmpty = (str: string) => (str.length > 0 ? str : null)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    registerPrivateCitizen({
      variables: {
        ...formValues,
        phone: nullIfEmpty(formValues.phone),
      },
    })
    e.preventDefault()
  }

  return (
    <React.Fragment>
      <Helmet title="User Registration" />
      <PanelWithContentLayout>
        <InfoPanel header="Device Registration">
          <p>Use this page to register with your contact details.</p>
          <p>
            We will collect your email, first name, last name and a phone number. You will also need to tick the Ts&Cs checkbox before
            clicking the Submit button.
          </p>
        </InfoPanel>
        <div>
          {genericError && <ErrorNotification />}

          <form onSubmit={handleSubmit} id="pc-registration-form">
            <FormContainer step={1} header="User information">
              <UserInfoForm formValues={formValues} formErrorsByField={formErrors} onChange={handleFormFieldChange} />
            </FormContainer>

            <FormContainer step={2} header="Terms and conditions">
              <TermsAndConditionsForm formValues={formValues} formErrorsByField={formErrors} onChange={handleFormFieldChange} />
            </FormContainer>

            <button className={cx('button', inputSizeClass, buttonStyles.roundedButton)}>Submit</button>
          </form>
        </div>
      </PanelWithContentLayout>
    </React.Fragment>
  )
}

export default PCRegistrationPage
