import React from 'react'
import { Link } from 'react-router-dom'
import styles from './scss/Header.module.scss'
import cx from 'classnames'

type MenuOption = {
  label: string
  path: string
  show: boolean
}

type MenuNavigationProps = {
  showMenu: boolean
  onItemClick: () => void
  options: MenuOption[]
}

const MenuNavigation: React.FC<MenuNavigationProps> = ({ showMenu, onItemClick, options }) => {
  const renderOptions = () => {
    return options.map((option: MenuOption) => {
      if (!option.show) return null

      return (
        <li key={option.label}>
          <Link to={option.path} onClick={onItemClick}>
            {option.label}
          </Link>
        </li>
      )
    })
  }
  return (
    <div className={cx(styles.menuContainer, { [styles.show]: showMenu })}>
      <main>
        <nav>
          <div className="columns">
            <ul className={cx(styles.menu, 'column is-one-fifth-desktop is-full-mobile is-full-tablet')}>{renderOptions()}</ul>
          </div>
        </nav>
      </main>
    </div>
  )
}

export default MenuNavigation
