import React from 'react'
import { FieldErrors } from './GraphQLErrorHandlers'

export class FormErrorHelper {
  fieldErrors: FieldErrors
  constructor(fieldErrors: FieldErrors = {}) {
    this.fieldErrors = fieldErrors
  }

  renderError(fieldName: string) {
    const errorMessages = this.fieldErrors[fieldName]
    if (errorMessages) {
      const cssClasses = `help is-danger ${fieldName}Error`
      return <p className={cssClasses}>{errorMessages.join(', ')}</p>
    }
  }
}
