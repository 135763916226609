import React from 'react'
import styles from './App.module.scss'
import Header from './components/Header'
import SwitchRoutes from './routes/SwitchRoutes'
import { BrowserRouter as Router } from 'react-router-dom'
import { Store } from './store'

import UserInformation from './components/UserInformation'

export default function Registration() {
  return (
    <Store>
      <div className={styles.App}>
        <Router>
          <UserInformation>
            <Header logoSrc="/dex-logo.svg" logoAlt="dex logo" />
            <div className={styles.container}>
              <SwitchRoutes />
            </div>
          </UserInformation>
        </Router>
      </div>
    </Store>
  )
}
