import React from 'react'

type StructuredOption = {
  label: string
  value: string
}

type SelectProps = {
  options?: string[]
  structuredOptions?: StructuredOption[]
  onChange?: any
  name?: string
  defaultValue?: string
  disabled?: boolean
  includeEmptyOption?: boolean
}

const Select: React.FC<SelectProps> = ({ options, structuredOptions, onChange, name, defaultValue, disabled, includeEmptyOption }) => {
  const empty = {
    label: 'Please select',
    value: '',
  }

  const prepareOptions = () => {
    let preparedOptions: StructuredOption[] = []
    if (options) {
      preparedOptions = options.map((option: string) => {
        return { label: option, value: option }
      })
    } else if (structuredOptions) {
      preparedOptions = structuredOptions
    }

    if (includeEmptyOption) {
      preparedOptions.unshift(empty)
    }

    return preparedOptions
  }

  const generateOption = (option: StructuredOption) => {
    const disabled = option.label === empty.label && option.value === empty.value

    return (
      <option value={option.value} key={option.value} disabled={disabled}>
        {option.label}
      </option>
    )
  }

  const generateOptions = () => {
    const preparedOptions = prepareOptions()

    if (preparedOptions) {
      return preparedOptions.map((option: StructuredOption) => {
        return generateOption(option)
      })
    }
  }

  return (
    <select name={name} onChange={onChange} defaultValue={defaultValue} disabled={disabled}>
      {generateOptions()}
    </select>
  )
}

export default Select
