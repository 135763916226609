import gql from 'graphql-tag'

export const REGISTER_LOCATION_MUTATION = gql`
  mutation RegisterLocation($nmi: String!, $postCode: String!, $state: String!, $retailer: String!) {
    registerLocation(input: { nmi: $nmi, postCode: $postCode, state: $state, retailer: $retailer }) {
      location {
        id
        nmi
        postCode
        state
        retailer
      }
    }
  }
`
