export const locationDefaults = {
  nmi: '',
  postCode: '',
  state: 'SA',
  retailer: '',
  customRetailer: '',
}

export const deviceDefaults = {
  deviceType: 'battery_only',
  manufacturer: '',
  customManufacturer: '',
  model: '',
  serialNumber: '',
  capacityKva: 0.1,
}
