import { GraphQLError } from 'graphql'
import { ApolloError } from 'apollo-boost'
import { captureMessage, captureException } from '@sentry/react'

export type FieldErrors = {
  [key: string]: string[]
}

const reportErrors = (errors: Error[]) => {
  if (errors.length > 1) {
    const message = errors.map((e) => e.message).join('\n')
    captureMessage(message)
  } else if (errors.length === 1) {
    captureException(errors[0])
  }
}

const transformGraphQLErrors = (graphqlErrors: readonly GraphQLError[], allowedFieldNames: string[] = []): [Error[], FieldErrors] => {
  let otherErrors: Error[] = []
  let fieldErrors: FieldErrors = {}

  graphqlErrors.forEach((error) => {
    if (error.extensions) {
      Object.keys(error.extensions).forEach((key) => {
        if (allowedFieldNames.includes(key)) {
          fieldErrors[key] = (fieldErrors[key] || []).concat(error.extensions![key])
        } else {
          otherErrors.push(error)
        }
      })
    } else {
      otherErrors.push(error)
    }
  })

  return [otherErrors, fieldErrors]
}

export const handleGraphQLErrors = (
  { message, graphQLErrors, networkError }: ApolloError,
  locationFormFields?: string[]
): [Error[], FieldErrors?] => {
  let genericErrors: Error[] = []
  let formErrors = undefined

  if (networkError) {
    genericErrors.push(networkError)
  } else if (graphQLErrors.length > 0) {
    const [otherErrors, fieldErrors] = transformGraphQLErrors(graphQLErrors, locationFormFields)

    if (otherErrors) {
      otherErrors.forEach((err) => genericErrors.push(err))
    }

    if (fieldErrors) {
      formErrors = fieldErrors
    }
  } else if (message) {
    genericErrors.push(new Error(message))
  }
  reportErrors(genericErrors)
  return [genericErrors, formErrors]
}
