import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import AuthenticatedRoute from './AuthenticatedRoute'
import AppContext from '../store/context/AppContext'
import DeviceRegistrationPage from '../pages/DeviceRegistrationPage'
import PCRegistrationPage from '../pages/PCRegistrationPage'
import ContactPage from '../pages/ContactPage'
import OffersPage from '../pages/OffersPage'

export default function SwitchRoutes() {
  let location = useLocation()
  useEffect(() => {
    ReactGA.pageview(location.pathname)
  }, [location])

  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/device_registration" />
      </Route>
      <Route path="/contact_support" component={ContactPage} />
      <AuthenticatedRoute path="/pc_registration">
        <AppContext.Consumer>
          {({ store }) => {
            if (store.privateCitizen && store.devices.length > 0) {
              return <Redirect to="/offers" />
            }
            if (store.privateCitizen) {
              return <Redirect to="/device_registration" />
            }
            return <PCRegistrationPage />
          }}
        </AppContext.Consumer>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/device_registration">
        <AppContext.Consumer>
          {({ store }) => (store.privateCitizen ? <DeviceRegistrationPage /> : <Redirect to="/pc_registration" />)}
        </AppContext.Consumer>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/offers">
        <AppContext.Consumer>
          {({ store }) => (store.devices.length > 0 ? <OffersPage /> : <Redirect to="/device_registration" />)}
        </AppContext.Consumer>
      </AuthenticatedRoute>
    </Switch>
  )
}
