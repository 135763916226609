import React from 'react'
import { Icon } from '@greensync/component-library'
import styles from './scss/tooltip.module.scss'
import cx from 'classnames'

type TooltipProps = {
  text: string
}

const Tooltip: React.FC<TooltipProps> = ({ text }) => {
  return (
    <div className={cx(styles.tooltip, 'has-tooltip-right')} data-tooltip={text}>
      <Icon>info</Icon>
    </div>
  )
}

export default Tooltip
