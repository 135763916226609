import React, { ReactNode, useState, forwardRef, useImperativeHandle } from 'react'
import styles from './scss/form-container.module.scss'
import { Icon } from '@greensync/component-library'
import cx from 'classnames'

export interface CollapsibleFormContainerComponent {
  toggleIsCollapsed: Function
}

type CollapsibleFormContainerProps = {
  title: string
  children: ReactNode
  step?: number | string
  completed?: boolean
  disabled: boolean
  initiallyCollapsed: boolean
  ref?: any
}

const CollapsibleFormContainer: React.FC<CollapsibleFormContainerProps> = forwardRef(
  ({ title, children, step, completed, disabled, initiallyCollapsed }, ref) => {
    const [collapsed, setCollapsed] = useState(initiallyCollapsed)

    const toggleIsCollapsed = () => {
      if (!disabled) {
        setCollapsed(!collapsed)
      }
    }

    if (ref) {
      useImperativeHandle(
        ref,
        (): CollapsibleFormContainerComponent => {
          return { toggleIsCollapsed }
        }
      )
    }

    const statusIcon = () => {
      if (completed === true) {
        return <Icon>check_circle</Icon>
      } else if (step !== undefined) {
        return (
          <div className={styles.step}>
            <div className={styles.stepValue}>{step}</div>
          </div>
        )
      }
    }

    const collapseBtn = () => {
      return <div className={styles.collapseButton}>{collapsed ? <Icon>expand_more</Icon> : <Icon>expand_less</Icon>}</div>
    }

    const content = () => {
      return <div className={styles.content}>{children}</div>
    }

    const enabled = !disabled
    const uncollapsed = !collapsed

    return (
      <div
        className={cx(
          styles.formContainer,
          { [styles.collapsed]: collapsed },
          { [styles.disabled]: disabled },
          { [styles.completed]: completed }
        )}
      >
        <div
          className={cx(styles.header, {
            [styles.clickable]: !disabled,
          })}
          onClick={toggleIsCollapsed}
        >
          <div className={styles.statusIcon}>{statusIcon()}</div>
          <div className={styles.title}>{title}</div>

          {enabled && collapseBtn()}
        </div>

        {enabled && uncollapsed && content()}
      </div>
    )
  }
)

export default CollapsibleFormContainer
