import React, { useContext } from 'react'
import AppContext from '../../store/context/AppContext'
import styles from './scss/pc-registration-page.module.scss'
import Tooltip from '../../components/Tooltip'
import { FormValues } from './types/FormValues'
import { FormErrorsByField } from './types/FormErrors'
import cx from 'classnames'
import inputSizeClass from '../../helpers/InputSizeHelper'

type UserInfoFormProps = {
  formValues: FormValues
  formErrorsByField: FormErrorsByField
  onChange: any
}

const UserInfoForm: React.FC<UserInfoFormProps> = ({ formValues, formErrorsByField, onChange }) => {
  const { store } = useContext(AppContext)
  const { userEmail } = store

  const renderErrorMessage = (fieldName: 'email' | 'firstName' | 'lastName' | 'phone' | 'termsAndConditions') => {
    const errorMessage: string | undefined = formErrorsByField[fieldName]

    return errorMessage && <p className="help is-danger">{errorMessage}</p>
  }

  return (
    <div className="columns is-desktop">
      <div className="column is-four-fifths-desktop">
        <div className="columns is-desktop">
          <div className="column">
            <div className={cx('field', styles.emailField)}>
              <label className="label">Email *</label>
              <div className="control">
                <input className={cx('input', inputSizeClass)} name="email" type="text" value={userEmail} disabled />
              </div>
              {renderErrorMessage('email')}
            </div>
          </div>
        </div>

        <div className="columns is-desktop">
          <div className="column is-half-desktop">
            <div className={cx('field', styles.firstNameField)}>
              <label className="label">First name *</label>
              <div className="control">
                <input
                  className={cx('input', inputSizeClass)}
                  name="firstName"
                  type="text"
                  value={formValues.firstName}
                  onChange={onChange}
                  required
                  minLength={2}
                  autoFocus
                />
              </div>
              {renderErrorMessage('firstName')}
            </div>
          </div>

          <div className="column is-half-desktop">
            <div className={cx('field', styles.lastNameField)}>
              <label className="label">Last name *</label>
              <div className="control">
                <input
                  className={cx('input', inputSizeClass)}
                  name="lastName"
                  type="text"
                  value={formValues.lastName}
                  onChange={onChange}
                  required
                  minLength={2}
                />
              </div>
              {renderErrorMessage('lastName')}
            </div>
          </div>
        </div>

        <div className="columns is-desktop">
          <div className="column is-half-desktop">
            <div className={cx('field', styles.phoneField)}>
              <label className="label">Phone number</label>
              <div className="columns is-gapless is-desktop">
                <div className="column is-four-fifths-desktop">
                  <div className="control">
                    <input
                      className={cx('input', inputSizeClass)}
                      name="phone"
                      type="tel"
                      pattern="^0[0-9]{9}$"
                      value={formValues.phone}
                      onChange={onChange}
                    />
                  </div>
                  {renderErrorMessage('phone')}
                </div>

                <div className="column">
                  <Tooltip text="Australian phone number with area code, eg. 03XXXXXXXX" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserInfoForm
