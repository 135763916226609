import React from 'react'
import Notification from '../Notification'

type ErrorNotificationProps = {
  message?: string
}

const ErrorNotification: React.FC<ErrorNotificationProps> = ({ message }) => {
  return (
    <Notification className="is-danger">
      <p data-test-error>The application has encountered an error. Please refresh the page and try again.</p>
      {message && (
        <React.Fragment>
          <p>Error details:</p>
          <p>{message}</p>
        </React.Fragment>
      )}
    </Notification>
  )
}

export default ErrorNotification
