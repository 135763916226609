import React from 'react'
import { IntlProvider } from 'react-intl'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'
import { HelmetProvider } from 'react-helmet-async'
import Registration from './Registration'

const graphqlClient = new ApolloClient({})

const App = () => {
  return (
    <ApolloProvider client={graphqlClient}>
      <IntlProvider locale="en-gb">
        <HelmetProvider>
          <Registration />
        </HelmetProvider>
      </IntlProvider>
    </ApolloProvider>
  )
}

export default App
