import React, { ReactNode } from 'react'
import { Icon } from '@greensync/component-library'
import { default as ReactPopup } from 'reactjs-popup'
import styles from './scss/popup.module.scss'

type PopupProps = {
  children: ReactNode
  position:
    | 'top left'
    | 'top center'
    | 'top right'
    | 'right top'
    | 'right center'
    | 'right bottom'
    | 'bottom left'
    | 'bottom center'
    | 'bottom right'
    | 'left top'
    | 'left center'
    | 'left bottom'
    | 'center center'
}

const Popup: React.FC<PopupProps> = ({ children, position }) => {
  const popupTrigger = () => {
    return (
      <div className={styles.triggerBtn}>
        <Icon>info</Icon>
      </div>
    )
  }

  return (
    <div className={styles.popupContainer}>
      <ReactPopup trigger={popupTrigger()} position={position} on="hover">
        <div>{children}</div>
      </ReactPopup>
    </div>
  )
}

export default Popup
