declare global {
  interface Window {
    __CONFIG_DATA__: {
      environment: string
      sentryDsn: string
      gaTrackingCode: string
    }
  }
}

export default window.__CONFIG_DATA__
