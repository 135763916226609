import React, { ReactNode } from 'react'
import styles from './scss/page-layout.module.scss'
import cx from 'classnames'

type PanelWithContentLayoutProps = {
  children: Array<ReactNode>
}

const PanelWithContentLayout: React.FC<PanelWithContentLayoutProps> = ({ children }) => {
  const panel = children[0]
  const mainContent = children[1]

  return (
    <div className={styles.gridWrapper}>
      <div className={cx('columns is-gapless is-desktop', styles.inheritHeight)}>
        <div className={cx(styles.sideColumn, 'column is-one-fifth-desktop is-full-mobile is-full-tablet')}>{panel}</div>

        <div className={cx('column', styles.inheritHeight)}>
          <div className={cx('columns', styles.paddedColumnsContainer, styles.bgContainer)}>
            <div
              className={cx(
                'column',
                'is-6-fullhd',
                'is-offset-3-fullhd',
                'is-8-desktop',
                'is-offset-2-desktop',
                'is-12-tablet',
                'is-offset-0-tablet',
                'is-12-mobile',
                'is-offset-0-mobile'
              )}
            >
              {mainContent}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PanelWithContentLayout
