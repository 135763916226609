import React, { ReactNode } from 'react'
import styles from './scss/form-container.module.scss'
import cx from 'classnames'

type FormContainerProps = {
  step: number | string
  header: string
  children: ReactNode
}

const FormContainer: React.FC<FormContainerProps> = ({ step, header, children }) => {
  return (
    <div className={styles.formContainer}>
      <div className={styles.header}>
        <div className={styles.statusIcon}>
          <div className={styles.step}>
            <div className={styles.stepValue}>{step}</div>
          </div>
        </div>
        <div className={styles.title}>{header}</div>
      </div>

      <div className={cx(styles.content)}>{children}</div>
    </div>
  )
}

export default FormContainer
