import React, { ReactNode } from 'react'
import styles from './scss/info-msg.module.scss'

type InfoMsgProps = {
  children: ReactNode
}

const InfoMsg: React.FC<InfoMsgProps> = ({ children }) => {
  return <div className={styles.infoMsg}>{children}</div>
}

export default InfoMsg
