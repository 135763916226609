import React from 'react'
import ReactGA from 'react-ga'
import styles from './scss/offers-list.module.scss'
import { Offer } from '../../types/Offer'
import buttonStyles from '../../styles/button.module.scss'

export type OffersListProps = {
  offers: Array<Offer>
}

export const OffersList: React.FC<OffersListProps> = ({ offers }) => {
  const renderOffer = (offer: Offer) => (
    <div className="column is-half-desktop is-one-third-widescreen" key={offer.id}>
      <div className={styles.offer}>
        <div className={styles.offerElements}>
          <div>
            <ReactGA.OutboundLink eventLabel={offer.externalUrl} to={offer.externalUrl} target="_blank">
              <img
                className={styles.offerImage}
                src={offer.imageUrl}
                alt={`Energy offer from ${offer.retailer}`}
                data-test-class="offer-image"
              />
            </ReactGA.OutboundLink>
          </div>

          <div className={styles.offerExternalLink}>
            <ReactGA.OutboundLink
              eventLabel={offer.externalUrl}
              to={offer.externalUrl}
              target="_blank"
              className={buttonStyles.roundedButton}
              data-test-class="offer-button"
            >
              View offer
            </ReactGA.OutboundLink>
          </div>
        </div>
      </div>
    </div>
  )

  return <div className="offersList columns is-multiline is-desktop">{offers.map(renderOffer)}</div>
}

export default OffersList
