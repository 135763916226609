import React, { ReactNode } from 'react'
import styles from './scss/page-layout.module.scss'
import cx from 'classnames'

type FullContentLayoutProps = {
  children: ReactNode
}

const FullContentLayout: React.FC<FullContentLayoutProps> = ({ children }) => {
  return (
    <div className={styles.gridWrapper}>
      <div className={cx('columns is-gapless is-desktop', styles.inheritHeight)}>
        <div className={cx('column', styles.inheritHeight)}>
          <div className={cx('columns', styles.paddedColumnsContainer, styles.bgContainer)}>
            <div
              className={cx(
                'column',
                'is-4-fullhd',
                'is-offset-4-fullhd',
                'is-8-desktop',
                'is-offset-2-desktop',
                'is-12-tablet',
                'is-offset-0-tablet',
                'is-12-mobile',
                'is-offset-0-mobile'
              )}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullContentLayout
