import React, { useState, useContext } from 'react'
import AppContext from '../../store/context/AppContext'
import styles from './scss/Header.module.scss'
import { Icon } from '@greensync/component-library'
import MenuNavigation from './MenuNavigation'
import { Link } from 'react-router-dom'

type HeaderProps = {
  logoSrc: string
  logoAlt: string
}

const Header: React.FC<HeaderProps> = ({ logoSrc, logoAlt }) => {
  const { store } = useContext(AppContext)
  const { userEmail } = store

  const [showMenu, setShowMenu] = useState(false)

  const hideMenu = () => {
    setShowMenu(false)
  }

  const renderLogInOrLogOutButton = () => {
    return userEmail ? renderLogOutButton() : renderLogInButton()
  }

  const renderLogInButton = () => (
    <Link to="/pc_registration" className={styles.loginButton}>
      <Icon>account_circle</Icon>
      <span className={styles.authLabel}>Log in / Register</span>
    </Link>
  )

  const renderLogOutButton = () => (
    <a href="/auth/logout" className={styles.loginButton}>
      <Icon>account_circle</Icon>
      <span className={styles.authLabel}>Log out</span>
    </a>
  )

  const menuOptions = [
    { label: 'Add a device', path: '/device_registration', show: true },
    { label: 'View offers', path: '/offers', show: store.devices.length > 0 },
    { label: 'Contact support', path: '/contact_support', show: true },
  ]

  return (
    <header className={styles.header}>
      <div className={styles.leftColumn}>
        <a href="https://dex.energy/locations/south-australia">
          <img src={logoSrc} className={styles.headerLogo} alt={logoAlt} />
        </a>
        <div className={styles.filtersContainer}></div>
      </div>
      <div className={styles.rightColumn}>
        <button className={styles.menuButton} onClick={() => setShowMenu(!showMenu)}>
          <Icon>{showMenu ? 'close' : 'menu'}</Icon>
        </button>
        {renderLogInOrLogOutButton()}
      </div>
      <MenuNavigation showMenu={showMenu} onItemClick={hideMenu} options={menuOptions} />
    </header>
  )
}

export default Header
