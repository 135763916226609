import React, { useState, ReactNode } from 'react'
import styles from './scss/notification.module.scss'
import cx from 'classnames'

type NotificationProps = {
  className?: 'is-primary' | 'is-link' | 'is-info' | 'is-success' | 'is-warning' | 'is-danger'
  closeButton?: boolean
  children: ReactNode
}

const Notification: React.FC<NotificationProps> = ({ className, closeButton, children }) => {
  const [closed, setClosed] = useState(false)

  if (closed) return <></>

  return (
    <div className={cx(styles.notification, 'notification', 'is-light', className)}>
      {closeButton && <button className="delete" onClick={() => setClosed(true)}></button>}

      {children}
    </div>
  )
}

export default Notification
