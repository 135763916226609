import { Route } from 'react-router-dom'
import AppContext from '../store/context/AppContext'

export default class AuthenticatedRoute extends Route {
  static contextType = AppContext

  render() {
    const store = this.context!.store

    if (!store.userEmail) {
      window.location.assign(`/auth/dex_energy?origin=${window.location.href}`)
      return null
    } else {
      return super.render()
    }
  }
}
