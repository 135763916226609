export const manufacturersData = ['Chargefox', 'Enphase', 'Metering Dynamics', 'SolarEdge', 'Sungrow', 'SwitchDin', 'Tesla', 'Other']

export const retailersData = [
  '1st Energy',
  'AGL',
  'Alinta Energy',
  'Amaysim',
  'Blue NRG',
  'Click Energy',
  'Commander Power & Gas',
  'Covau',
  'Diamond Energy',
  'Discover Energy',
  'Energy Australia',
  'ERM Business Energy',
  'Flow Power',
  'Glow Bird',
  'Lumo Energy',
  'Momentum Energy',
  'Next Business Energy',
  'Online Power & Gas',
  'Origin Energy',
  'People Energy',
  'Power Direct',
  'Power Shop',
  'QEnergy',
  'Red Energy',
  'Simply Energy',
  'Sumo Power',
  'Tango Energy',
  'Other',
  "Don't want to say",
]

export const statesData = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA']
