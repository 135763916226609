import React from 'react'
import { Viewer } from '../types/Viewer'
import { PrivateCitizen } from '../types/PrivateCitizen'
import { Location } from '../types/Location'
import { Device } from '../types/Device'

export interface IState {
  userEmail: string | undefined
  privateCitizen: PrivateCitizen | undefined
  locations: Location[]
  devices: Device[]
  lastRegisteredLocation: Location | undefined
}

export type SetStateFromViewerQuery = {
  type: 'SetStateFromViewerQuery'
  payload: {
    viewer: Viewer
  }
}

export type SetPrivateCitizen = {
  type: 'SetPrivateCitizen'
  payload: {
    privateCitizen: PrivateCitizen
  }
}

export type AddLocation = {
  type: 'AddLocation'
  payload: {
    location: Location
  }
}

export type ResetLocation = {
  type: 'ResetLocation'
}

export type AddDevice = {
  type: 'AddDevice'
  payload: {
    device: Device
  }
}

export type IAction = SetStateFromViewerQuery | SetPrivateCitizen | AddLocation | AddDevice | ResetLocation

// these functions help us make sure we never have an unhandled action
// function invalidAction(action: never, state: IState): never // this will cause typescript to fail compilation if we missed a case
function invalidAction(action: any, previousState: IState) {
  // if the issue happens at run time, report an error
  return previousState
}

function explodeViewer(payload: { viewer: Viewer }) {
  const newState: IState = {
    userEmail: payload.viewer.userEmail,
    privateCitizen: undefined,
    locations: [],
    devices: [],
    lastRegisteredLocation: undefined,
  }
  if (payload.viewer.privateCitizen) {
    const shallowPrivateCitizen: PrivateCitizen = {
      ...payload.viewer.privateCitizen,
    }
    shallowPrivateCitizen.locations = undefined
    newState.privateCitizen = shallowPrivateCitizen
    if (payload.viewer.privateCitizen.locations) {
      newState.locations = payload.viewer.privateCitizen.locations.map((location: Location) => {
        const shallowLocation: Location = { ...location }
        shallowLocation.devices = undefined
        return shallowLocation
      })
      newState.devices = payload.viewer.privateCitizen.locations.reduce((acc: any, location: any) => {
        return acc.concat(location.devices)
      }, [])
    }
  }
  return newState
}

const reducer: React.Reducer<IState, IAction> = (previousState, action) => {
  switch (action.type) {
    case 'SetStateFromViewerQuery':
      return explodeViewer(action.payload)
    case 'SetPrivateCitizen':
      return {
        ...previousState,
        privateCitizen: action.payload.privateCitizen,
      }
    case 'AddLocation':
      return {
        ...previousState,
        locations: previousState.locations.concat(action.payload.location),
        lastRegisteredLocation: action.payload.location,
      }
    case 'AddDevice':
      return {
        ...previousState,
        devices: previousState.devices.concat(action.payload.device),
      }
    case 'ResetLocation':
      return {
        ...previousState,
        lastRegisteredLocation: undefined,
      }
    default:
      return invalidAction(action, previousState)
  }
}

export default reducer
