import React, { useState } from 'react'
import Select from '../../components/Select'
import Popup from '../../components/Popup'
import { Location } from '../../types/Location'
import { locationDefaults, retailersData, statesData } from '../../configuration'
import { FieldErrors } from '../../helpers/GraphQLErrorHandlers'
import { FormErrorHelper } from '../../helpers/FormErrorHelper'
import buttonStyles from '../../styles/button.module.scss'
import cx from 'classnames'
import inputSizeClass from '../../helpers/InputSizeHelper'

type NewLocationFormProps = {
  value?: Location
  readOnly: boolean
  fieldErrors: FieldErrors
  onFormComplete: Function
}

export const locationFormFields = ['nmi', 'postCode', 'state', 'retailer', 'customRetailer']

const NewLocationForm: React.FC<NewLocationFormProps> = ({ value, readOnly, fieldErrors, onFormComplete }) => {
  const currentRetailer = (value && value.retailer) || ''
  let retailerValue = currentRetailer
  let customRetailerValue = ''
  if (currentRetailer.length > 0 && !retailersData.includes(currentRetailer)) {
    retailerValue = 'Other'
    customRetailerValue = currentRetailer
  }

  const [nmi, setNmi] = useState((value && value.nmi) || '')
  const [postCode, setPostCode] = useState((value && value.postCode) || '')
  const [state, setState] = useState((value && value.state) || locationDefaults.state)
  const [retailer, setRetailer] = useState(retailerValue)
  const [customRetailer, setCustomRetailer] = useState(customRetailerValue)

  const onNmiChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNmi(event.target.value.trim())
  }
  const onPostCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPostCode(event.target.value.trim())
  }
  const onStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value.trim())
  }
  const onRetailerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRetailer(event.target.value.trim())
  }
  const onCustomRetailerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomRetailer(event.target.value.trim())
  }

  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    let location = {
      nmi,
      postCode,
      state,
      retailer: retailer === 'Other' ? customRetailer : retailer,
    }
    onFormComplete(location)
  }

  const retailerUnknown = retailer === 'Other'
  const errorHelper = new FormErrorHelper(fieldErrors)

  return (
    <form onSubmit={onSubmitForm} className="location-form">
      <fieldset disabled={readOnly}>
        <div className="columns is-desktop">
          <div className="column is-four-fifths-desktop">
            <div className="columns is-desktop">
              <div className="column">
                <div className="field">
                  <label className="label">NMI *</label>
                  <div className="columns is-gapless is-no-margin-bottom is-desktop">
                    <div className="column is-four-fifths-desktop">
                      <div className="control">
                        <input
                          className={cx('input', inputSizeClass)}
                          type="text"
                          name="nmi"
                          required
                          pattern="^[a-zA-Z0-9]{10,11}$"
                          value={nmi}
                          onChange={onNmiChange}
                          onBlur={onNmiChange}
                          autoFocus
                        />
                      </div>
                    </div>

                    <div className="column">
                      <Popup position="right top">
                        <div>
                          <p>You can find NMI on your bill</p>
                          <img src="/bill_nmi_lookup.png" alt="Bill NMI Lookup" />
                        </div>
                      </Popup>
                    </div>
                  </div>

                  {errorHelper.renderError('nmi')}
                </div>
              </div>

              <div className="column">
                <div className="field">
                  <label className="label">Postcode *</label>
                  <div className="control">
                    <input
                      className={cx('input', inputSizeClass)}
                      type="text"
                      name="postCode"
                      required
                      pattern="^[0-9]{4}$"
                      value={postCode}
                      onChange={onPostCodeChange}
                      onBlur={onPostCodeChange}
                    />
                  </div>
                  {errorHelper.renderError('postCode')}
                </div>
              </div>

              <div className="column">
                <div className="field">
                  <label className="label">State *</label>
                  <div className="control">
                    <div className={cx('select', inputSizeClass)}>
                      <Select options={statesData} name="state" onChange={onStateChange} defaultValue={state} />
                    </div>
                  </div>
                  {errorHelper.renderError('state')}
                </div>
              </div>
            </div>

            <div className="columns is-desktop">
              <div className="column is-two-fifths-desktop">
                <div className="field">
                  <label className="label">Current retailer *</label>
                  <div className="control">
                    <div className={cx('select', inputSizeClass)}>
                      <Select
                        options={retailersData}
                        includeEmptyOption={true}
                        onChange={onRetailerChange}
                        name="retailer"
                        defaultValue={retailer}
                      />
                    </div>
                  </div>
                  {errorHelper.renderError('retailer')}
                </div>
              </div>

              {retailerUnknown && (
                <div className="column is-two-fifths-desktop">
                  <div className="field">
                    <label className="label">Retailer name *</label>
                    <div className="control">
                      <input
                        className={cx('input', inputSizeClass)}
                        type="text"
                        name="customRetailer"
                        value={customRetailer}
                        onChange={onCustomRetailerChange}
                        onBlur={onCustomRetailerChange}
                        required
                        minLength={2}
                        autoFocus
                      />
                    </div>
                    {errorHelper.renderError('customRetailer')}
                  </div>
                </div>
              )}
            </div>

            {!readOnly && (
              <div className="columns">
                <div className="column">
                  <button type="submit" className={cx('button', inputSizeClass, buttonStyles.roundedButton)}>
                    Save
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </fieldset>
    </form>
  )
}

export default NewLocationForm
