import React, { Dispatch } from 'react'
import { IState, IAction } from '../reducer'
import { initialState } from '../initialState'

export interface AppContextInterface {
  store: IState
  dispatch: Dispatch<IAction>
}

const AppContext = React.createContext<AppContextInterface>({
  store: initialState,
  dispatch: () => {
    console.warn('Default dispatch called, something wrong with AppContext setup?')
  },
})

export default AppContext
